<template>
  <v-container class="fill-height pt-0" fluid>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-alert
          v-model="alert"
           color="primaryLight"
          dense
          type="info"
          class="text-center black--text caption mb-0"
          dismissible
          >{{$t('userAlrt')}}</v-alert
        >
      </v-col>
    </v-row>

    <v-row class="d-flex flex-row py-1">
      <div class="text-left black--text px-3" style="margin-top:3%">
        {{$t('users')}}
      </div>
      <v-spacer></v-spacer>

      <v-col cols="3" md="2" class="text-right px-0 mt-1">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="9" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>

    <v-row>
      <v-col cols="12" md="9"> </v-col>
      <v-col cols="12" md="3" class="text-right">
        <v-btn color="primary" text  @click.prevent="openDialog()">
          <v-icon left color="primary">mdi-plus-circle-outline</v-icon>{{$t('create_user')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" v-for="user in getUsers" :key="user.name">
        <user-card
          :user="user"
          @edit="editUser"
          @delete="openConfirmeDialog"
          @show="showUser"
        />
      </v-col>
    </v-row>
    <add :dialog="dialog" :show="show" :user="user" @close="closeAddDialog()" />
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('userQ')"
      @yes="deleteUser"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-container>
</template>

<script>
import { GET_USERS_ENTITY_QUERY } from './../graphql/Query'
import { DELETE_USER_MUTATION } from './../graphql/Mutation'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import Add from './../components/AddUserDialog.vue'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import UserCard from './../components/UserCard.vue'
import User from '@/models/User'
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'User',
  mixins: [
    dialogMixins
  ],
  components: {
    Add,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    UserCard
  },
  data: () => ({
    alert: true,
    show: false,
    user: new User(),
    users: []
  }),
  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY
    }
  },
  watch: {
    users: function(val) {
      this.setUsersState(val)
    }
  },
  computed: {
    ...mapGetters({
      getUsers: 'user/getUsers',
      modules: "auth/getModules"
    }),
    totalUsers () {
      return this.modules.reduce((accumulator, currentModule) => {
        return accumulator + currentModule.totalUsers;
      }, 0);
    },
  },
  methods: {
    ...mapActions({
      removeUserState: 'user/removeUser',
      setUsersState: 'user/setUsers'
    }),
    closeAddDialog () {
      this.user = new User()
      this.dialog = false;
      this.show = false;
    },
    async deleteUser (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_USER_MUTATION,
          variables: { id }
        })
        this.removeUserState(id)
        this.success = this.$t('usersuccess')
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    editUser (user) {
      this.user = user
      this.dialog = true
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    openDialog () {
      if (this.modules.length > 0) {
        if (this.totalUsers > this.users.length) {
          this.dialog = true;
        } else {
          // eslint-disable-next-line no-undef
          Fire.$emit('packageDialog', 1);
          // Fire.$emit('upgradePackageDialog');
        }
      } else {
        // eslint-disable-next-line no-undef
        Fire.$emit('packageDialog', 0);
      }
    },
    showUser (user) {
      this.user = user
      this.show = true
      this.dialog = true
    },
  }
}
</script>